/*
@tailwind base;

@tailwind components;
*/

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #FFF;
}

.bg-gray-50 {
  background-color: #FAFAFA;
}

.bg-gray-100 {
  background-color: #F5F5F5;
}

.bg-gray-200 {
  background-color: #EEEEEE;
}

.bg-gray-600 {
  background-color: #757575;
}

.bg-red-500 {
  background-color: #F44336;
}

.bg-green-300 {
  background-color: #81C784;
}

.bg-amber-600 {
  background-color: #FFB300;
}

.bg-green-highlight-200 {
  background-color: #D4D9D9;
}

.bg-green-highlight {
  background-color: #A8C0A7;
}

.bg-theme-primary {
  background-color: #231231;
}

[data-tier="starter"] .starter\:bg-theme-secondary-900 {
  background-color: #231231;
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-gray-200:hover {
  background-color: #EEEEEE;
}

.hover\:bg-theme-primary-hover:hover {
  background-color: #A48ADB;
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.border-black {
  border-color: #000000;
}

.border-gray-400 {
  border-color: #BDBDBD;
}

.border-gray {
  border-color: #9E9E9E;
}

.border-theme-secondary {
  border-color: #663F80;
}

.hover\:border-transparent:hover {
  border-color: transparent;
}

.rounded-4 {
  border-radius: .4rem;
}

.rounded-6 {
  border-radius: .6rem;
}

.rounded-8 {
  border-radius: .8rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded {
  border-radius: .4rem;
}

.rounded-lg {
  border-radius: .8rem;
}

.rounded-b-8 {
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem;
}

.border-solid {
  border-style: solid;
}

.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-b-1 {
  border-bottom-width: 1px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

[data-tier="starter"] .starter\:hidden {
  display: none;
}

[data-tier="enterprise"] .enterprise\:hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.content-center {
  align-content: center;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-600 {
  font-weight: 600;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.h-8 {
  height: 0.8rem;
}

.h-12 {
  height: 1.2rem;
}

.h-16 {
  height: 1.6rem;
}

.h-24 {
  height: 2.4rem;
}

.h-32 {
  height: 3.2rem;
}

.h-40 {
  height: 4rem;
}

.h-48 {
  height: 4.8rem;
}

.h-52 {
  height: 5.2rem;
}

.h-64 {
  height: 6.4rem;
}

.h-128 {
  height: 12.8rem;
}

.h-136 {
  height: 13.6rem;
}

.h-200 {
  height: 20rem;
}

.h-400 {
  height: 40rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.leading-none {
  line-height: 1;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.m-4 {
  margin: 0.4rem;
}

.m-6 {
  margin: 0.6rem;
}

.m-8 {
  margin: 0.8rem;
}

.m-16 {
  margin: 1.6rem;
}

.m-32 {
  margin: 3.2rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}

.my-36 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
}

.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}

.my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-2 {
  margin-right: 0.2rem;
}

.mb-2 {
  margin-bottom: 0.2rem;
}

.ml-2 {
  margin-left: 0.2rem;
}

.mt-4 {
  margin-top: 0.4rem;
}

.mr-4 {
  margin-right: 0.4rem;
}

.mb-4 {
  margin-bottom: 0.4rem;
}

.ml-4 {
  margin-left: 0.4rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.mr-6 {
  margin-right: 0.6rem;
}

.mb-6 {
  margin-bottom: 0.6rem;
}

.ml-6 {
  margin-left: 0.6rem;
}

.mt-8 {
  margin-top: 0.8rem;
}

.mr-8 {
  margin-right: 0.8rem;
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.ml-8 {
  margin-left: 0.8rem;
}

.mt-10 {
  margin-top: 1.0rem;
}

.mr-10 {
  margin-right: 1.0rem;
}

.mb-10 {
  margin-bottom: 1.0rem;
}

.ml-10 {
  margin-left: 1.0rem;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mr-12 {
  margin-right: 1.2rem;
}

.mb-12 {
  margin-bottom: 1.2rem;
}

.ml-12 {
  margin-left: 1.2rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mr-16 {
  margin-right: 1.6rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.ml-16 {
  margin-left: 1.6rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.ml-20 {
  margin-left: 2rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.ml-24 {
  margin-left: 2.4rem;
}

.mr-28 {
  margin-right: 2.8rem;
}

.mr-32 {
  margin-right: 3.2rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.ml-32 {
  margin-left: 3.2rem;
}

.mt-36 {
  margin-top: 3.6rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mt-44 {
  margin-top: 4.4rem;
}

.mt-48 {
  margin-top: 4.8rem;
}

.mb-48 {
  margin-bottom: 4.8rem;
}

.mt-56 {
  margin-top: 5.6rem;
}

.mr-56 {
  margin-right: 5.6rem;
}

.mb-56 {
  margin-bottom: 5.6rem;
}

.mb-64 {
  margin-bottom: 6.4rem;
}

.ml-80 {
  margin-left: 8rem;
}

.ml-auto {
  margin-left: auto;
}

[data-tier="enterprise"] .enterprise\:mt-24 {
  margin-top: 2.4rem;
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem;
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem;
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem;
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem;
}

.max-h-384 {
  max-height: 38.4rem;
}

.max-h-448 {
  max-height: 44.8rem;
}

.max-h-640 {
  max-height: 64rem;
}

.max-w-288 {
  max-width: 28.8rem;
}

.max-w-320 {
  max-width: 32rem;
}

.max-w-384 {
  max-width: 38.4rem;
}

.max-w-512 {
  max-width: 51.2rem;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-xl {
  max-width: 96rem;
}

.max-w-2xl {
  max-width: 112rem;
}

.max-w-full {
  max-width: 100%;
}

.min-h-64 {
  min-height: 6.4rem;
}

.min-h-128 {
  min-height: 12.8rem;
}

.min-h-160 {
  min-height: 16rem;
}

.min-h-224 {
  min-height: 22.4rem;
}

.min-h-288 {
  min-height: 28.8rem;
}

.min-w-20 {
  min-width: 2rem;
}

.min-w-40 {
  min-width: 4rem;
}

.min-w-64 {
  min-width: 6.4rem;
}

.min-w-lg {
  min-width: 80rem;
}

.min-w-xl {
  min-width: 96rem;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 0.2rem;
}

.p-4 {
  padding: 0.4rem;
}

.p-6 {
  padding: 0.6rem;
}

.p-10 {
  padding: 1.0rem;
}

.p-12 {
  padding: 1.2rem;
}

.p-16 {
  padding: 1.6rem;
}

.p-20 {
  padding: 2rem;
}

.p-24 {
  padding: 2.4rem;
}

.p-32 {
  padding: 3.2rem;
}

.p-48 {
  padding: 4.8rem;
}

.p-64 {
  padding: 6.4rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
}

.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}

.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}

.px-60 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-80 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-2 {
  padding-top: 0.2rem;
}

.pr-2 {
  padding-right: 0.2rem;
}

.pt-4 {
  padding-top: 0.4rem;
}

.pb-4 {
  padding-bottom: 0.4rem;
}

.pl-4 {
  padding-left: 0.4rem;
}

.pt-6 {
  padding-top: 0.6rem;
}

.pr-6 {
  padding-right: 0.6rem;
}

.pt-8 {
  padding-top: 0.8rem;
}

.pb-8 {
  padding-bottom: 0.8rem;
}

.pb-10 {
  padding-bottom: 1.0rem;
}

.pb-12 {
  padding-bottom: 1.2rem;
}

.pl-12 {
  padding-left: 1.2rem;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pr-16 {
  padding-right: 1.6rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pl-16 {
  padding-left: 1.6rem;
}

.pt-20 {
  padding-top: 2rem;
}

.pb-20 {
  padding-bottom: 2rem;
}

.pl-20 {
  padding-left: 2rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pr-24 {
  padding-right: 2.4rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pl-24 {
  padding-left: 2.4rem;
}

.pt-28 {
  padding-top: 2.8rem;
}

.pt-32 {
  padding-top: 3.2rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pl-32 {
  padding-left: 3.2rem;
}

.pb-36 {
  padding-bottom: 3.6rem;
}

.pl-40 {
  padding-left: 4rem;
}

.pt-44 {
  padding-top: 4.4rem;
}

.pt-48 {
  padding-top: 4.8rem;
}

.pr-48 {
  padding-right: 4.8rem;
}

.pb-48 {
  padding-bottom: 4.8rem;
}

.pl-52 {
  padding-left: 5.2rem;
}

.pl-56 {
  padding-left: 5.6rem;
}

.pb-64 {
  padding-bottom: 6.4rem;
}

.pl-64 {
  padding-left: 6.4rem;
}

.pl-72 {
  padding-left: 7.2rem;
}

.pr-80 {
  padding-right: 8rem;
}

.pl-80 {
  padding-left: 8rem;
}

.pl-88 {
  padding-left: 8.8rem;
}

[data-tier="starter"] .starter\:p-24 {
  padding: 2.4rem;
}

[data-tier="starter"] .starter\:px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

[data-tier="starter"] .starter\:pt-0 {
  padding-top: 0;
}

[data-tier="starter"] .starter\:pr-48 {
  padding-right: 4.8rem;
}

[data-tier="starter"] .starter\:pl-88 {
  padding-left: 8.8rem;
}

[data-tier="enterprise"] .enterprise\:pl-24 {
  padding-left: 2.4rem;
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem;
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem;
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1\/2 {
  top: 50%;
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0;
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0;
}

.resize {
  resize: both;
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-none {
  box-shadow: none;
}

[data-tier="enterprise"] .enterprise\:shadow-0 {
  box-shadow: none;
}

.table-fixed {
  table-layout: fixed;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  color: #000000;
}

.text-white {
  color: #FFF;
}

.text-grey {
  color: #9E9E9E;
}

.text-gray-300 {
  color: #E0E0E0;
}

.text-gray {
  color: #9E9E9E;
}

.text-red-500 {
  color: #F44336;
}

.text-red {
  color: #F44336;
}

.text-green-500 {
  color: #4CAF50;
}

.text-green {
  color: #4CAF50;
}

.text-blue {
  color: #2196F3;
}

.text-theme-accent-400 {
  color: #7D61BC;
}

.text-theme-secondary-900 {
  color: #231231;
}

.text-theme-secondary {
  color: #663F80;
}

[data-tier="starter"] .starter\:text-white {
  color: #FFF;
}

.hover\:text-white:hover {
  color: #FFF;
}

.text-12 {
  font-size: 1.2rem;
}

.text-13 {
  font-size: 1.3rem;
}

.text-14 {
  font-size: 1.4rem;
}

.text-16 {
  font-size: 1.6rem;
}

.text-18 {
  font-size: 1.8rem;
}

.text-20 {
  font-size: 2rem;
}

.text-24 {
  font-size: 2.4rem;
}

.text-28 {
  font-size: 2.8rem;
}

.text-32 {
  font-size: 3.2rem;
}

.text-36 {
  font-size: 3.6rem;
}

.text-40 {
  font-size: 4rem;
}

.text-96 {
  font-size: 9.6rem;
}

.text-128 {
  font-size: 12.8rem;
}

.text-xs {
  font-size: 1.2rem;
}

.text-sm {
  font-size: 1.4rem;
}

.text-base {
  font-size: 1.6rem;
}

.text-lg {
  font-size: 1.8rem;
}

.text-xl {
  font-size: 2rem;
}

.text-2xl {
  font-size: 2.4rem;
}

.text-3xl {
  font-size: 3rem;
}

.text-4xl {
  font-size: 3.6rem;
}

.text-5xl {
  font-size: 4.8rem;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.align-top {
  vertical-align: top;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-16 {
  width: 1.6rem;
}

.w-24 {
  width: 2.4rem;
}

.w-32 {
  width: 3.2rem;
}

.w-36 {
  width: 3.6rem;
}

.w-40 {
  width: 4rem;
}

.w-48 {
  width: 4.8rem;
}

.w-60 {
  width: 6rem;
}

.w-64 {
  width: 6.4rem;
}

.w-72 {
  width: 7.2rem;
}

.w-96 {
  width: 9.6rem;
}

.w-128 {
  width: 12.8rem;
}

.w-160 {
  width: 16rem;
}

.w-192 {
  width: 19.2rem;
}

.w-224 {
  width: 22.4rem;
}

.w-288 {
  width: 28.8rem;
}

.w-360 {
  width: 36rem;
}

.w-400 {
  width: 40rem;
}

.w-432 {
  width: 43.2rem;
}

.w-512 {
  width: 51.2rem;
}

.w-auto {
  width: auto;
}

.w-xs {
  width: 32rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-1\/4 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-3\/12 {
  width: 25%;
}

.w-9\/12 {
  width: 75%;
}

.w-full {
  width: 100%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-9999 {
  z-index: 9999;
}

.gap-2 {
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.gap-6 {
  grid-gap: 0.6rem;
  gap: 0.6rem;
}

.gap-8 {
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.gap-12 {
  grid-gap: 1.2rem;
  gap: 1.2rem;
}

.gap-16 {
  grid-gap: 1.6rem;
  gap: 1.6rem;
}

.gap-20 {
  grid-gap: 2rem;
  gap: 2rem;
}

.gap-28 {
  grid-gap: 2.8rem;
  gap: 2.8rem;
}

.gap-96 {
  grid-gap: 9.6rem;
  gap: 9.6rem;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.rotate-180 {
  --transform-rotate: 180deg;
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%;
}

.transition-all {
  transition-property: all;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.transition-transform {
  transition-property: transform;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

/* add missing rotate-x tailwind-esque classes here */

.rotate-x-180 {
  transform: rotateX(180deg);
}

/* manually add some new "!important" build-ins from Tailwind v2.1+ (can be removed when we bump versions) */

/* DEV NOTE::these classes for some reason also have to be added to the `purgecss`' whitelist array (though they didn't use to...) */

@media (min-width: 600px) {

  .sm\:flex {
    display: flex;
  }

  .sm\:h-320 {
    height: 32rem;
  }

  .sm\:mt-88 {
    margin-top: 8.8rem;
  }

  .sm\:p-24 {
    padding: 2.4rem;
  }

  .sm\:p-32 {
    padding: 3.2rem;
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .sm\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .sm\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pt-12 {
    padding-top: 1.2rem;
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem;
  }

  .sm\:pt-24 {
    padding-top: 2.4rem;
  }

  .sm\:text-40 {
    font-size: 4rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }
}

@media (min-width: 960px) {

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .lg\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0;
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0;
  }

  .lg\:visible {
    visibility: visible;
  }
}
