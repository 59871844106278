@import-normalize ;

@import 'tailwind-base.css';

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
    transition: none !important;
    animation: none !important;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 1500;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
    /* To target bulk action menus in tables */
    table .MuiDrawer-paper::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 20px #5D5D5D;
    }
    /* To approach scrollbar styling on Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #c2c2c2 transparent;
    }
    table .MuiDrawer-paper {
        scrollbar-color: rgb(93, 93, 93) transparent;
    }
}

.table-cell-wrapped-text {
    padding-top:6px !important;
    padding-bottom:6px !important;
}

.MuiDialogTitle-root .MuiIconButton-root {
    height: 32px;
    width: 32px;
    margin: 2px 8px;
}

form label {
    z-index: 99;
}

.handle, .row-handle {
    cursor: grab;
}

.react-draggable-dragging {
    position: relative;
    z-index: 1;
}

.react-draggable-dragging .handle, .react-draggable-dragging .row-handle {
    cursor: grabbing;
}

.MuiDrawer-paper .MuiListItem-root:hover {
    color: #a48adb;
}

/* This serves to make sure sticky headers stick vertically but not horizontally, and that they center vertically */
.MuiTableCell-stickyHeader {
    left: auto !important;
    line-height: 1;
}

/* DATE PICKER */
.MuiPickersDay-current {
    border: 1px solid black;
}

.MuiPickersDay-daySelected {
    background: rebeccapurple;
}

.picked-date-range:not(.MuiPickersDay-daySelected) {
    background: lightgrey;
    border-radius: 0;
    padding: 0 20px;
    margin: 0;
}

.other-selected p {
    color: white;
}

div[role="presentation"]:first-child .picked-date-range {
    border-radius: 50% 0 0 50%;
}

div[role="presentation"]:last-child .picked-date-range {
    border-radius: 0 50% 50% 0;
}

.unchosen input {
    opacity: .54;
}

.unchosen fieldset {
    border: 1px solid rgba(0, 0, 0, .26);
}

.chosen fieldset, .unchosen fieldset:hover {
    border: 2px solid black;
}

.chosen, .unchosen {
    width: 100%;
}

.chosen button, .unchosen button {
    width: 24px;
    height:24px;
}

.chosen .MuiFormHelperText-contained, .unchosen .MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0
}
/* END date picker styling */

/* TEXT FIELDS */
.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.26);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87);
}

/* END text field styles */

.MuiTableCell-root {
    border: none;
}

/* this makes the focus ripple form a square */
.MuiTouchRipple-root *, span[class^='MuiTouchRipple-root-'] *, span[class*=' MuiTouchRipple-root-'] * {
    border-radius: 8px;
}

.filterCheck {
    height:42px;
}

.filterCheck:hover {
    background: rgba(0, 0, 0, 0.04);
}

.masterFilter:hover {
    width: 100%;
    position: relative;
    padding-left: 35px;
    left: -35px;
}

/* potential Safari bug fix */
svg {
    resize: both;
}

/* #fuse-footer {
    position: fixed;
    top: calc(100vh - 48px);
} */

/* keep all card headers the proper level of bold */
.MuiPaper-root .MuiTypography-h6 {
    font-weight: 600;
}

/* so that all icons within a table are size small */
table .MuiIconButton-root {
    padding: 3px;
    font-size: 1.8rem;
    height: 24px;
    width: 24px;
}

table .MuiIconButton-root svg {
    height: 20px;
    width: 20px;
}

.MuiInputBase-root {
    height: 36.5px;
}

.MuiInputBase-multiline {
    height: unset;
}

.MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
}

@keyframes autofill {
    0%,100% {
        background: transparent;
    }
}
input:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill!important;
    animation-fill-mode: both;
    -webkit-box-shadow:0 0 0 100px transparent inset!important;
}

/* GLOBAL STYLES */
.drawer-menu-container {
    position: relative;
    overflow-x: hidden;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bg-radial-green {
    background: radial-gradient(#FFFFFF 20%, #ACE693)
}

.bg-radial-grey {
    background: radial-gradient(#FFFFFF 20%, #BDBDBD)
}

.bg-radial-yellow {
    background: radial-gradient(#FFFFFF 20%, #E6BF93)
}

.bg-radial-red {
    background: radial-gradient(#FFFFFF 20%, #E69393)
}
/* END */

/* GLOBAL RULES */
/* Hide Microsoft Edge "password reveal" button */
::-ms-reveal {
    display: none;
}

input::placeholder {
    font-style: italic;
}
/* END */

/* OTHER */
/* manually add some new "!important" build-ins from Tailwind v2.1+ (can be removed when we bump versions) */
.\!underline {
    text-decoration: underline !important;
}

.\!text-white {
    color: #FFFFFF !important;
}

.\!px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.\!mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
/* END */

@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@import 'tailwind.css';
